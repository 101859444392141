:root {
  --primary-font: "Merriweather", serif;
  --secondary-font: "Ruthie", cursive;
  --content-font: "Lora", serif;
  --second-title: "Big Shoulders Display", cursive;
  --third-style: "'Ubuntu', sans-serif";
}

.page_container {
  margin: 0 auto;
  max-width: 1020px;
}

.slogan,
.link_to_subpage {
  font-family: var(--second-title);
  font-size: 25px;
  text-align: center;
  padding: 20px;
  color: rgb(111, 182, 140);
  font-weight: bold;
}

.link_to_subpage:hover {
  color: rgb(111, 182, 140);
}

.focus_title {
  font-family: var(--second-title);
  font-size: 50px;
  text-align: center;
}
.second_title {
  font-family: var(--secondary-font);
  font-size: 40px;
  text-align: center;
}
.head_section_style {
  font-family: var(--primary-font);
  font-size: 30px;
}
.content {
  font-family: var(--content-font);
  text-align: center;
  padding-inline: 20px;
}
.call_to_action {
  font-family: var(--content-font);
  text-align: center;
}

.services {
  font-family: var(--content-font);
  padding: 30px;
  line-height: 40px;
}

.media_style {
  max-width: 90%;
  display: block;
  margin: 0 auto;
  padding: 20px;
}

.medium_size_img {
  display: block;
  margin: 0 auto;
}
.name_banner {
  max-width: 100%;
  display: block;
  margin: 0 auto;
  padding: 30px;
}

.contactPageLinks {
  list-style: none;
  text-align: center;
  font-family: var(--second-title);
  font-size: 40px;
  text-decoration: none;
  color: black;
  padding: 20px;
}

.recipesPageLinks {
  list-style: none;
  font-family: var(--content-font);
  font-size: 16px;
  text-decoration: none;
  color: black;
  padding: 10px;
}

.recipeStyle {
  font-family: var(--third-style);
  padding: 50px;
  text-align: center;
  font-size: 35px;
  font-weight: 700;
}

.puzzle_size {
  display: none;
}

.buttonServices {
  display: block;
  margin: 0 auto;
  line-height: 1;
  border-radius: 0;
  max-width: 90%;
  background-color: white;
}

.titleServices {
  font-family: var(--secondary-font);
  font-size: 60px;
  text-align: center;
  padding: 10px;
  text-shadow: 0 0 black;
}

.headerServices {
  font-family: var(--second-title);
  font-size: 19px;
  font-weight: 700;
  letter-spacing: -1px;
  line-height: 1;
  font-size: 40px;
  text-align: center;
  padding: 10px;
  text-shadow: 0 0 black;
}

.contentServices {
  letter-spacing: -0.4px;
  line-height: 1.6;
  font-family: var(--content-font);
  padding: 20px;
  padding: 30px;
}

.linkServices {
  line-height: 56px;
  text-decoration: none;
  font-family: var(--second-title);
  text-align: center;
  padding: 20px;
  font-size: 30px;
  color: rgb(111, 182, 140);
  font-weight: bold;
  text-shadow: 0 0 black;
}

.linkServices:hover {
  color: rgb(111, 182, 140);
}
@media (min-width: 800px) {
  .media_style {
    max-width: 50%;
  }

  .recipeStyle {
    font-size: 50px;
  }

  .second_title {
    font-size: 80px;
  }
}
