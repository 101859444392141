:root {
  --primary-font: "Merriweather", serif;
  --secondary-font: "Ruthie", cursive;
  --content-font: "Lora", serif;
  --second-title: "Big Shoulders Display", cursive;
  --third-style: "'Ubuntu', sans-serif";
}

/* .nav_bar {
  background: radial-gradient(
    788px at 0.7% 3.4%,
    rgb(164, 231, 192) 0%,
    rgb(255, 255, 255) 90%
  );
} */

.nav_bar {
  background-color: #a1e1bb;
}

.nav_title {
  font-size: larger;
}

.nav_menu,
.nav_title {
  text-decoration: none;
  padding: 10px;
  font-weight: 500;
  color: rgba(245, 245, 245, 0.829);
  font-family: var(--second-title);
  font-size: 29px;
  text-align: center;
}

.banner {
  padding: 0;
}

.contact_links {
  padding: 0;
  display: flex;
  justify-content: center;
}
.contact_list {
  list-style: none;
  padding: 10px;
}
.footer_icon {
  color: green;
}
@media (min-width: 800px) {
  .nav_menu,
  .nav_title {
    text-decoration: none;
    padding: 10px;
    font-weight: 500;
    color: whitesmoke;
    margin: 15px;
  }
}
